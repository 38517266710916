.title {
  font-size: clamp(2rem, 5vw, 3rem);
  margin-bottom: 0.5em;
  text-align: center;
}

.subtitle {
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  color: rgba(0, 0, 0, 0.75);
  text-align: center;
}

.section-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: clamp(1.5rem, 3vw, 2rem);
}

.paragraph {
  font-size: clamp(1rem, 2vw, 1.2rem);
  line-height: 1.6;
  max-width: 80%;
  text-align: center;
  margin: 0 auto;
}

.big-paragraph {
  font-size: clamp(1rem, 2vw, 1.2rem);
  line-height: 1.6;
  max-width: 50%;
  text-align: center;
  margin: 0 auto;
}

.feature-title {
  font-size: clamp(1.2rem, 2.5vw, 1.5rem);
  margin-top: 1rem;
}

.card-title {
  font-size: clamp(1.3rem, 2vw, 1.5rem);
  line-height: 1.6;
  max-width: 80%;
  text-align: center;
  margin: 0 auto;
}

.card-description {
  font-size: clamp(1rem, 2vw, 1.2rem);
  color: #888;
  line-height: 1.6;
  max-width: 80%;
  text-align: center;
  margin: 0 auto;
}

.button {
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
}

.italic-paragraph {
  font-style: italic;
}
